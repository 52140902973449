body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a {
  color: #000;
  text-decoration: none;
}

a:active {
  color: #000;
}

a:visited {
  color: #000;
}


.Toastify__toast-body {
  padding: 0 !important;
}

