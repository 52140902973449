body {
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Segoe UI Web (West European), Segoe, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

.greco-header__app-launcher {
  display: none;
}
.greco-header__tick {
  display: none;
}

.app-wrapper--panelIsOpen {
    width: calc(100% - 340px);
}
.app-wrapper {
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;


  /* background-image: url(./assets/GrECo_Logo_Erste.jpg);
  background-size: 6%;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;  */
}

#fluent-default-layer-host {
  z-index: 10000000 !important;
}

.tabTable tbody tr{
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-collapse: collapse;
  }
  
  [data-icon-name="CircleFill"] {
    margin-top: 3px;
  }
  
  .ms-DatePicker-goToday {
    right: 14px;
  }
  .Toastify__toast-container {
  z-index: 123123123123123 !important;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888888; /* Change the thumb color */
    border-radius: 10px;        /* Add rounded corners to the thumb */
  }
  
  /* Add a hover effect */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa; /* Change the thumb color on hover */
  }